<template>
  <div class="dashboard-content">
    <div class="db-tile ">
      <h4>Booking List</h4>
    </div>
    <div class="row responsive-row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="text-center">
                <img
                  alt=""
                  class="img-fluid rounded-circle"
                  src="/static/images/thumb-4.jpg"
                  width="90"
                  height="90"
                >
                <h6 class="mt-2 font-weight-bold">
                  Ethan Moore
                </h6>
              </div>
              <div class="flex-grow-1 px-5">
                <h4 class="mb-3">
                  Soylent Vency Cafe   <span class="badge badge-pill badge-danger text-uppercase">Pending</span>
                </h4>
                <div class="mb-4">
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-map-marker mr-2"
                        aria-hidden="true"
                      />  <small class="text-uppercase font-weight-bold">Address</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      9986 Bear Hill Dr. Mocksville, NC 27028.
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-calendar mr-2"
                        aria-hidden="true"
                      />  <small class="text-uppercase font-weight-bold">Booking Date</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      20.08.2019
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-info-circle mr-2"
                        aria-hidden="true"
                      />   <small class="text-uppercase font-weight-bold">Booking Details</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      4 Tickets(Economy)
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-phone mr-2"
                        aria-hidden="true"
                      /> <small class="text-uppercase font-weight-bold">Contact No.</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      +123 123 456 45
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div class="detail-list mb-4 text-center">
                  <div class="detail-list-label mb-1">
                    <h5 class="">
                      Price
                    </h5>
                  </div>
                  <h4 class="m-0 text-small text-muted">
                    $459
                  </h4>
                </div>
                <button
                  class="btn btn-1 btn-primary"
                  type="button"
                >
                  Approve
                </button>
                <button
                  class="btn btn-1 btn-danger"
                  type="button"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row responsive-row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="text-center">
                <img
                  alt=""
                  class="img-fluid rounded-circle"
                  src="/static/images/thumb-4.jpg"
                  width="90"
                  height="90"
                >
                <h6 class="mt-2 font-weight-bold">
                  Ethan Moore
                </h6>
              </div>
              <div class="flex-grow-1 px-5">
                <h4 class="mb-3">
                  Soylent Vency Cafe   <span class="badge badge-pill badge-danger text-uppercase">Pending</span>
                </h4>
                <div class="mb-4">
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-map-marker mr-2"
                        aria-hidden="true"
                      />  <small class="text-uppercase font-weight-bold">Address</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      9986 Bear Hill Dr. Mocksville, NC 27028.
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-calendar mr-2"
                        aria-hidden="true"
                      />  <small class="text-uppercase font-weight-bold">Booking Date</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      20.08.2019
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-info-circle mr-2"
                        aria-hidden="true"
                      />   <small class="text-uppercase font-weight-bold">Booking Details</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      4 Tickets(Economy)
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-phone mr-2"
                        aria-hidden="true"
                      /> <small class="text-uppercase font-weight-bold">Contact No.</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      +123 123 456 45
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div class="detail-list mb-4 text-center">
                  <div class="detail-list-label mb-1">
                    <h5 class="">
                      Price
                    </h5>
                  </div>
                  <h4 class="m-0 text-small text-muted">
                    $459
                  </h4>
                </div>
                <button
                  class="btn btn-1 btn-primary"
                  type="button"
                >
                  Approve
                </button>
                <button
                  class="btn btn-1 btn-danger"
                  type="button"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row responsive-row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="text-center">
                <img
                  alt=""
                  class="img-fluid rounded-circle"
                  src="/static/images/thumb-1.jpg"
                  width="90"
                  height="90"
                >
                <h6 class="mt-2 font-weight-bold">
                  Ethan Moore
                </h6>
              </div>
              <div class="flex-grow-1 px-5">
                <h4 class="mb-3">
                  Soylent Vency Cafe   <span class="badge badge-pill badge-danger text-uppercase">Pending</span>
                </h4>
                <div class="mb-4">
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-map-marker mr-2"
                        aria-hidden="true"
                      />  <small class="text-uppercase font-weight-bold">Address</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      9986 Bear Hill Dr. Mocksville, NC 27028.
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-calendar mr-2"
                        aria-hidden="true"
                      />  <small class="text-uppercase font-weight-bold">Booking Date</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      20.08.2019
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-info-circle mr-2"
                        aria-hidden="true"
                      />   <small class="text-uppercase font-weight-bold">Booking Details</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      4 Tickets(Economy)
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-phone mr-2"
                        aria-hidden="true"
                      /> <small class="text-uppercase font-weight-bold">Contact No.</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      +123 123 456 45
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div class="detail-list mb-4 text-center">
                  <div class="detail-list-label mb-1">
                    <h5 class="">
                      Price
                    </h5>
                  </div>
                  <h4 class="m-0 text-small text-muted">
                    $459
                  </h4>
                </div>
                <button
                  class="btn btn-1 btn-primary"
                  type="button"
                >
                  Approve
                </button>
                <button
                  class="btn btn-1 btn-danger"
                  type="button"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row responsive-row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="text-center">
                <img
                  alt=""
                  class="img-fluid rounded-circle"
                  src="/static/images/thumb-4.jpg"
                  width="90"
                  height="90"
                >
                <h6 class="mt-2 font-weight-bold">
                  Ethan Moore
                </h6>
              </div>
              <div class="flex-grow-1 px-5">
                <h4 class="mb-3">
                  Soylent Vency Cafe   <span class="badge badge-pill badge-danger text-uppercase">Pending</span>
                </h4>
                <div class="mb-4">
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-map-marker mr-2"
                        aria-hidden="true"
                      />  <small class="text-uppercase font-weight-bold">Address</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      9986 Bear Hill Dr. Mocksville, NC 27028.
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-calendar mr-2"
                        aria-hidden="true"
                      />  <small class="text-uppercase font-weight-bold">Booking Date</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      20.08.2019
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-info-circle mr-2"
                        aria-hidden="true"
                      />   <small class="text-uppercase font-weight-bold">Booking Details</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      4 Tickets(Economy)
                    </p>
                  </div>
                  <div class="detail-list mb-2">
                    <div class="detail-list-label mb-1">
                      <i
                        class="fa fa-phone mr-2"
                        aria-hidden="true"
                      /> <small class="text-uppercase font-weight-bold">Contact No.</small>
                    </div>
                    <p class="m-0 text-small text-muted">
                      +123 123 456 45
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div class="detail-list mb-4 text-center">
                  <div class="detail-list-label mb-1">
                    <h5 class="">
                      Price
                    </h5>
                  </div>
                  <h4 class="m-0 text-small text-muted">
                    $459
                  </h4>
                </div>
                <button
                  class="btn btn-1 btn-primary"
                  type="button"
                >
                  Approve
                </button>
                <button
                  class="btn btn-1 btn-danger"
                  type="button"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>
